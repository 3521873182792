.cate-payment {
  padding: 4rem 0;

  .shipping-info {
    margin-bottom: 2.4rem;
    padding: 1.6rem 2.4rem;

    .shipping-title {
      border-bottom: 0.1rem solid #f2f2f2;
      padding-top: 0.4rem;
      padding-bottom: 1.4rem;
      margin-bottom: 1.6rem;

      p {
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-semibold);
      }
    }

    .shipping-detail {
      .address-item {
        width: 100%;
        display: inline-grid;
        overflow: hidden;

        .address-main {
          font-weight: var(--font-weight-bold);
          word-break: break-all;
          margin-bottom: 0.6rem;
        }

        .address-detail {
          line-height: 21px;
          word-break: break-all;
        }
      }
    }
  }

  .summary-content {
    padding: 1.6rem 2.4rem;

    .invoice {

      div {
        width: 100%;
        padding: 0.5rem;
      }

      input {
        padding: 0.5rem;
      }
    }

    .summary-title {
      border-bottom: 0.1rem solid #f2f2f2;
      padding-top: 0.2rem;
      padding-bottom: 1.4rem;
      margin-bottom: 0.5rem;

      p {
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-semibold);
      }
    }

    .summary-list {
      .product-card {
        display: flex;
        flex-direction: row;
        padding: 1.4rem 0 1.4rem;

        &__cover {
          width: 125px;
          height: 125px;
          min-width: 125px;

          img {
            width: 100%;
            height: 100%;
            border-radius: var(--border-radius-small);
            object-fit: cover;
            user-select: none;
          }

          svg {
            width: 100%;
            height: 100%;
          }
        }

        &__main {
          position: relative;
          width: 100%;
          margin-left: 1.6rem;
          padding: 0.5rem 0;

          .product-title {
            font-size: var(--font-size-5);
            font-weight: 500;
            margin-bottom: 0.8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .product-property {
            font-size: var(--font-size-6);
            font-weight: 500;
            color: #999;
            margin-bottom: 0.8rem;
          }

          .product-quantity {
            font-size: var(--font-size-6);
            font-weight: 500;
            color: #999;
            margin-bottom: 0.8rem;
          }

          .product-price {
            position: absolute;
            bottom: 0;
            left: 0;
            padding-bottom: 0.8rem;
            font-size: var(--font-size-5);
            font-weight: 600;
          }
        }
      }
    }
  }

  .payment-overview {
    padding: 1.6rem 2.4rem 2.4rem;

    .overview-title {
      margin-bottom: 1.6rem;
    }

    .overview-body {
      .price-group {
        border-top: 1px solid #f2f2f2;

        dl {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 1.6rem 0;

          dt {
            flex: 1 1;
            font-weight: 500;
          }

          dd {
            font-weight: 500;
            color: #999;
          }
        }
      }
    }

    .overview-total {
      border-top: 1px solid #f2f2f2;
      margin-bottom: 3.2rem;
      padding-top: 1.6rem;

      dl {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        dt {
          flex: 1 1;
          font-size: var(--font-size-base);
          font-weight: var(--font-weight-semibold);
        }

        dd {
          font-size: var(--font-size-3);
          font-weight: var(--font-weight-bold);
        }
      }
    }

    .overview-btn {
      button {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        font-size: var(--font-size-5);
      }
    }
  }
}

/*estilos para el form JS de Mercadopago*/
#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.container {
  height: 18px;
  display: inline-block;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  padding: 1px 2px;
}