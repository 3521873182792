:root {
  // Extra small devices (less than 768px)
  // No media query since this is the default
  --break-xs: 0px;
  // Medium devices (tablets, 768px and up)
  --break-sm: 768px;
  // Large devices (desktops, 1024 and up)
  --break-md: 1024px;
  --break-lg: 1200px;
  // Extra large devices (large desktops, 1440px and up)
  --break-xl: 1440px;
  --break-xxl: 1600px;

  /*
  * 2. Background vars
  */

  // --color-background-body: #f8f8f8;
  --color-background-body: #fafaf8;
  // --color-background-body: #f2f2f2;
  // --color-background-body: #f6f7f2;
  --color-background-base: #fff;
  --color-background-primary: #ff9a55;
  --color-background-alt: #ebecf0;

  /*
  * 3. Text vars
  */

  --color-text-base: #0e0e10;
  --color-text-alt: #2f2f35;
  --color-text-white: #f7f7f7;
  --color-text-green: #4a993a;

  --font-family: 'Montserrat', sans-serif;

  --font-size-1: 5.4rem;
  --font-size-2: 3.6rem;
  --font-size-3: 2.4rem;
  --font-size-4: 2rem;
  --font-size-5: 1.6rem;
  --font-size-6: 1.4rem;
  --font-size-7: 1.2rem;
  --font-size-base: 1.4rem;

  --font-weight-bold: 700;
  --font-weight-hdr: 400;
  --font-weight-normal: 400;
  --font-weight-semibold: 600;

  /*
  * 4. Colors vars
  */

  --color-primary: #ff9a55;
  --color-primary-darker: #ee7c31;
  --color-primary-light: #fff3ec;
  --color-accent: #779bbc;
  --color-accent-darker: #5b7086;
  --color: rgba(0, 0, 0, 0.25);
  --color-red: #f44336;

  --color-grey: rgb(221, 213, 213);

  /*
  * 5. Shadows vars
  */

  --shadow-elevation-1: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  --shadow-elevation-2: 0 0 0 1px rgba(63, 63, 68, 0.1), 0 2px 5px 0 rgba(63, 63, 68, 0.1);
  --shadow-elevation-3: 0 0 0 1px rgba(63, 63, 68, 0.1), 0px 3px 5px 0 rgba(0, 0, 0, 0.2);
  --shadow-elevation-4: 0 12px 32px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.05);

  /*
  * 6. Border vars
  */

  --border-radius-large: 1.2rem;
  --border-radius-medium: 0.8rem;
  --border-radius-none: 0;
  --border-radius-rounded: 9000px;
  --border-radius-small: 0.4rem;
}