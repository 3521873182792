.cate-blog {
  .cate-blog-content {
    padding: 4rem 0;
    .cate-blog-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__list {
        width: 100%;
      }
      .cate-blog-filter {
        position: sticky;
        top: 90px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 550px;
        max-width: 161px;
        min-width: 161px;
        margin-left: 2.4rem;
        z-index: 8;
        &__title {
          text-align: center;
          padding-top: 2.4rem;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      & {
        padding: 2.4rem 0;
      }
      .cate-blog-wrap {
        flex-direction: column-reverse;
        .cate-blog-filter {
          top: 80px;
          height: 100%;
          max-width: 100%;
          margin-left: 0;
          margin-bottom: 2.4rem;
          &__title {
            padding-top: 1.6rem;
          }
        }
      }
    }
  }
}
