.concept {
  // background-color: rgb(224, 222, 222);
  padding: 6px 5px;
  font-weight: 700;
}

.modal-membership {
  h4 {
    margin: 10px 0px;
  }

  h5 {
    margin-top: 30px;
    margin-bottom: 7px;
  }
}


.cate-membership {
  padding: 4rem 0;

  .membership-header {
    margin-bottom: 4rem;

    h1 {
      color: var(--color-text-alt);
      font-size: var(--font-size-2);
      line-height: 1.2;
      margin-bottom: 1.6rem;
    }

    p {
      font-size: var(--font-size-5);
    }
  }

  .membership-prices {
    width: 100%;

    .MuiGrid-item {
      width: 100%;
    }

    .cards-grid {
      width: 100%;
      display: grid;
      justify-content: center;
      grid-gap: 20px;
      grid-template-columns: 1fr;
      grid-template-rows: 3fr;

      .card-item {
        display: grid;
        align-items: center;
        justify-items: center;
      }

      @media screen and (min-width: 1300px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;

        .card-item:nth-child(3n) {
          grid-column: span 2;
          margin: 20px 0px;
        }
      }

      @media screen and (min-width: 1910px) {
        grid-template-columns: 1fr 1fr 1fr;

        .card-item:nth-child(3n) {
          grid-column: span 1;
        }
      }
    }

    .pricing-card {
      min-width: 300px;
      width: 470px;
      height: 830px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2.4rem 2.4rem;

      &--default {
        background-color: var(--color-background-base);
      }

      &--primary {
        background-color: var(--color-background-primary);
      }

      &__body {
        height: 100%;

        .pricing-header {
          height: 8%;
          text-align: center;
          font-size: 3.2rem;
          font-weight: var(--font-weight-bold);
        }

        .pricing-total {
          margin-bottom: 2.4rem;
          text-align: center;

          .price {
            font-weight: var(--font-weight-bold);
            font-size: 4.8rem;
            margin-right: 0.8rem;
          }
        }

        .pricing-desc {
          font-size: 1.6rem;
          margin-bottom: 1.6rem;
        }

        .pricing-list {
          height: 92%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          img {
            height: 200px;
            -webkit-filter: drop-shadow(5px 5px 5px #222);
            filter: drop-shadow(5px 5px 5px #222);
          }

          .categories-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: 15px 0px;

            .concepts-container {
              margin: 10px 0px;

            }

            .button-container {
              display: flex;
              flex-direction: column;

              .MuiButtonBase-root {
                align-self: stretch;
                margin-bottom: 15px;
              }
            }

            p {
              margin: 8px 0px;
              font-weight: bold;
              font-size: 1.7rem;
            }
          }

          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 2.4rem 0;
          }

          .list-icon {
            width: 1.8rem;
            height: 1.8rem;
            user-select: none;
          }

          .list-text {
            font-size: 1.6rem;
            margin-left: 0.8rem;
          }

          .buttons-container td {
            background-color: transparent;
            padding-top: 16px;
            border: none;
          }

          .hidden-cell {
            border: none;
            background-color: transparent;
          }
        }
      }

      &__action {
        padding: 13px 32px;

        &.action-primary {
          background-color: #fff;
          color: var(--color-primary);
        }
      }
    }
  }
}