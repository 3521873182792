.cate-about {
  min-height: calc(100vh - 72px);
}

.top_text_30 {
  margin-top: 30px !important;
}

.text__center {
  text-align: center;
}

.text__left {
  text-align: left !important;
}