.cate-subscription-detail {
    padding: 4rem 0;

    .cate-subscription-container {
        display: flex;
        justify-content: space-between;
    }

    @media only screen and (max-width: 890px) {
        .cate-subscription-container {
            flex-direction: column;
        }
    }

    .description-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 70%;

        h1 {
            font-size: var(--font-size-3);
            text-align: center;
        }

        .concepts-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 70%;
            padding: 15px;

            h1 {
                font-size: var(--font-size-4);
                margin-top: 20px;
                text-align: center;
            }

            p {
                font-size: var(--font-size-6);
                margin-bottom: 20px;
            }

            img {
                width: 140px;
                margin: 15px 0px;
            }

            .information-container {
                width: 300px;
                padding: 15px;
                text-align: center;

                h1 {
                    font-size: var(--font-size-4);
                    font-weight: bold;
                }

                p {
                    font-size: var(--font-size-5);
                }


            }

            .concepts {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;




                .name {
                    margin: 4px 0px;
                    width: 400px;
                    background-color: var(--color-background-primary);
                    border-radius: var(--border-radius-medium);
                }

                h4 {
                    padding: 7px 15px;
                    font-size: var(--font-size-6);
                }
            }

            .total-price {
                font-size: var(--font-size-3);
            }
        }
    }

    @media only screen and (max-width: 890px) {
        .description-container {
            width: 100%;

            .concepts-container {
                width: 100%;
            }
        }
    }

    .buy-grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .buy-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px;
            border-radius: var(--border-radius-large);
            height: 200px;
            box-shadow: 5px 7px 10px -2px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 5px 7px 10px -2px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 5px 7px 10px -2px rgba(0, 0, 0, 0.75);

            button {
                align-self: center;
            }

            p {
                margin: 10px 0px;
                text-align: center;
                font-size: var(--font-size-6);
            }

            a {
                font-size: var(--font-size-5);
                font-weight: bold;
                text-align: center;
            }
        }
    }
}