.cate-main-footer {
  padding: 1.6rem 0 2.4rem 0;
  background: var(--color-background-base);
  &__cont {
    padding: 3.2rem 0;
    .cont-logo {
      width: 120px;
      user-select: none;
    }
    .cont-links {
      h6 {
        font-weight: var(--font-weight-bold);
      }
      li {
        margin-top: 1.2rem;
      }
    }
    .cont-action {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .btn-auth {
        margin-bottom: 1.6rem;
        width: 130px;
        button {
          width: 100%;
          padding: 0.8rem 1.6rem;
        }
      }
    }
  }
  &__botton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3.2rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    .botton-left {
      display: flex;
      align-items: center;
      .icon-copyright {
        height: 1.6rem;
        margin-right: 0.4rem;
        svg {
          display: inline-block;
          width: 1.6rem;
          height: 1.6rem;
          font-size: 1.6rem;
        }
      }
    }
    .botton-right {
      display: flex;
      align-items: center;
      .icon-social {
        width: 3.2rem;
        height: 3.2rem;
        margin-left: 0.8rem;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        svg {
          width: 3.2rem;
          height: 3.2rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    &__botton {
      flex-direction: column;
      padding: 2.4rem 0;
      .botton-left {
        margin-bottom: 0.8rem;
      }
    }
    &__cont {
      padding: 2.4rem 0;
      .cont-action {
        align-items: flex-start;
      }
    }
  }
}
