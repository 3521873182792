.cate-reservation-list {
  margin: 4rem 0;
  .reservation-card {
    display: flex;
    &__cover {
      height: 180px;
      width: 260px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        user-select: none;
      }
    }
    &__content {
      position: relative;
      width: 100%;
      margin: 1.6rem;
      flex: 1 1;
      h6 {
        font-weight: 600;
        font-size: var(--font-size-5);
        line-height: 1.57143;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0.4rem 0;
      }
      p {
        margin-bottom: 0.4rem;
      }
      .content-state {
        position: absolute;
        top: 0;
        right: 0;
        .state-success {
          color: #389e0d;
          background: #f6ffed;
          border: 1px solid #b7eb8f;
          border-radius: 0.4rem;
          padding: 0.2rem 0.8rem;
        }
        .state-error {
          color: #cf1322;
          background: #fff1f0;
          border: 1px solid #ffa39e;
          border-radius: 0.4rem;
          padding: 0.2rem 0.8rem;
        }
      }
    }
    @media (max-width: 720px) {
      flex-direction: column;
      &__cover {
        width: 100%;
      }
      &__content {
        width: auto;
      }
    }
  }
}
