.card-user-plan {
  .plan-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f2f2f2;
    border-bottom: 1px #ddd solid;
    padding: 3.2rem 1.6rem;
    h6 {
      font-size: var(--font-size-4);
      font-weight: var(--font-weight-bold);
    }
    .plan-delete {
      padding: 0.4rem;
      border-radius: var(--border-radius-rounded);
      border: 1px transparent solid;
      transition: 0.2s ease;
      cursor: pointer;
      &:hover {
        background-color: rgba(244, 67, 54, 0.25);
        border: 1px #f44336 dashed;
        svg {
          color: #f44336;
        }
      }
      svg {
        display: block;
        font-size: 2.2rem;
      }
    }
  }
  .plan-content {
    display: flex;
    flex-direction: column;
    &__info {
      flex: 1;
      padding: 2.4rem 1.6rem;
    }
    &__invoice {
      flex: 0;
      margin: 0 1.6rem;
      padding: 1.6rem 0;
      border-top: 1px solid #ddd;
      h6 {
        font-weight: 600;
        margin-bottom: 0.2rem;
        text-transform: capitalize;
      }
      p {
        font-weight: 500;
      }
      .space {
        margin-top: 1rem;
      }
    }
  }
  @media (min-width: 768px) {
    .plan-content {
      flex-direction: row;
      &__info {
        flex: 1;
      }
      &__invoice {
        min-width: 220px;
        margin: 1.6rem 0;
        padding: 0 2.4rem;
        border-top: none;
        border-left: 1px solid #ddd;
      }
    }
  }
}
