.cate-register {
  // min-height: calc(100vh - 72px);
  &__grid {
    min-height: calc(100vh - 72px);
    .register-cover {
      display: flex;
      align-items: center;
      height: 100%;
      user-select: none;
    }
    .register-card {
      display: flex;
      align-items: center;
      height: 100%;
      &__paper {
        width: 100%;
        // height: 700px;
        padding: 2.4rem;
        margin: 2.4rem 0;
      }
      .card-header {
        .header-t1 {
          font-size: var(--font-size-3);
          font-weight: var(--font-weight-semibold);
        }
        .header-t2 {
          font-size: var(--font-size-2);
          font-weight: var(--font-weight-bold);
          color: var(--color-primary);
        }
        .header-t3 {
          margin-top: 1.6rem;
          font-size: var(--font-size-base);
          font-weight: 500;
        }
      }
      .card-content {
        height: 80%;
        display: flex;
        align-items: center;
        &__form {
          width: 100%;
          .form-btn-submit {
            margin-top: 1.6rem;
            margin-bottom: 2.4rem;
          }
          .form-link {
            text-align: center;
            color: var(--color-primary);
            .link-black {
              color: var(--color-text-base);
            }
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      .register-cover {
        display: none;
      }
    }
  }
}
