.cate-not-found {
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 72px);
    color: #565959;
    img {
      height: 384px;
      margin-top: -6.4rem;
      user-select: none;
    }
    h4 {
      font-size: var(--font-size-3);
      margin-top: 1.6rem;
    }
  }
  @media only screen and (max-width: 768px) {
    &__wrap {
      img {
        height: 224px;
        margin-top: -3.2rem;
      }
    }
  }
}
