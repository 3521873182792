.cate-shopcart {
  padding: 4rem 0;
  .shopping-cart-list {
    &__container {
      padding: 1.6rem 2.4rem;
      .shopping-cart-title {
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 1rem;
        margin-bottom: 0.5rem;
        h2 {
          font-size: var(--font-size-3);
        }
      }
      .shopping-cart-product {
        display: flex;
        flex-direction: row;
        padding: 1rem 0 1.5rem;
        .product-image {
          position: relative;
          width: 120px;
          height: 120px;
          a {
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: #000;
              opacity: 0.04;
              border-radius: var(--border-radius-small);
            }
          }
          img {
            max-width: 120px;
            max-height: 120px;
            height: 100%;
            border-radius: var(--border-radius-small);
            object-fit: cover;
            user-select: none;
          }
        }
        .product-main {
          -ms-flex: 1 1;
          flex: 1 1;
          padding-left: 1.5rem;
          .product-title {
            max-width: 460px;
            font-size: var(--font-size-5);
            font-weight: 500;
            margin-bottom: 5px;
            a {
              word-break: break-all;
              &:hover {
                text-decoration: underline;
                color: var(--color-primary);
              }
            }
          }
          .product-attr {
            dl {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              padding: 0.2rem 0 0.4rem;
              font-size: var(--font-size-7);
              dt {
                margin-right: 0.4rem;
                font-weight: var(--font-weight-semibold);
              }
            }
          }
          .product-price {
            margin-top: 0.8rem;
            &__main {
              display: flex;
              align-items: center;
              font-size: var(--font-size-5);
              line-height: 24px;
              font-weight: 900;
            }
          }
          .product-logistics {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
            margin-top: 1rem;
            font-size: var(--font-size-7);
            &__cost {
            }
            &__delivery {
              margin-top: 0.2rem;
            }
          }
        }
        .product-action {
          .action-group {
            text-align: right;
            margin-bottom: 16px;
            &__btn-delete {
              color: rgba(0, 0, 0, 0.7);
              cursor: pointer;
              button {
                margin: -0.4rem;
                padding: 0.4rem;
              }
            }
          }
          .product-num {
            text-align: right;
            &__picker {
              display: inline-block;
              width: 80px;
              &__wrap {
                display: inline-table;
                border-collapse: separate;
                border-spacing: 0;
                width: 100%;
                line-height: 0;
              }
              .pick-before {
                display: table-cell;
                vertical-align: middle;
                white-space: nowrap;
              }
              .pick-input {
                display: inline-table;
                border-collapse: separate;
                height: 36px;
                width: 100%;
                vertical-align: middle;
                input {
                  height: 36px;
                  width: 100%;
                  padding: 0;
                  border: none;
                  outline: none;
                  text-align: center;
                  vertical-align: middle;
                  background-color: transparent;
                  font-size: var(--font-size-base);
                  color: #2e9cc3;
                }
              }
              .pick-after {
                display: table-cell;
                vertical-align: middle;
                white-space: nowrap;
              }
              .btn-pick {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: none;
                background: #f2f2f2;
                cursor: pointer;
                svg {
                  font-size: 2rem;
                }
                &.disable {
                  cursor: not-allowed;
                  opacity: 0.45;
                }
              }
            }
            &__stock-tip {
              color: #ff7f01;
              font-size: var(--font-size-7);
              font-weight: var(--font-weight-semibold);
              line-height: 16px;
            }
          }
        }
      }
      .shopping-cart-empty {
        text-align: center;
        color: #565959;
        svg {
          font-size: 9.6rem;
          margin-top: 1.6rem;
        }
        p {
          font-size: var(--font-size-5);
          font-weight: var(--font-weight-semibold);
          margin: 1.6rem 0;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      &__container {
        padding: 1.6rem;
        .shopping-cart-product {
          flex-direction: column;
          padding: 1.6rem 0 1.6rem;
          .product-image {
            width: 100%;
            height: 160px;
            img {
              width: 100%;
              max-width: 100%;
              max-height: 160px;
              object-fit: contain;
            }
            .action-group {
              position: absolute;
              top: 0;
              right: 0;
              .action-group__btn-delete {
                width: 24px;
                height: 24px;
                margin: 0.6rem 0.6rem 0 0;
                padding: 0.2rem;
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.1);
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 1px, rgba(0, 0, 0, 0.24) 0px 1px 1px;
                // box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.2);
                opacity: 0.6;
              }
            }
          }
          .product-main {
            width: 100%;
            padding-top: 1.6rem;
            padding-left: 0;
          }
        }
      }
    }
  }
  .price-overview {
    &__container {
      padding: 1.6rem 2.4rem;
      .overview-title {
        margin-bottom: 2.4rem;
        h3 {
          font-weight: var(--font-weight-bold);
        }
      }
      .overview-body {
        .price-group {
          dl {
            display: flex;
            align-items: center;
            justify-content: space-between;
            dt {
              flex: 1 1;
              font-size: var(--font-size-base);
              font-weight: 500;
            }
            dd {
              font-size: var(--font-size-base);
              font-weight: 500;
              padding-left: 8px;
            }
          }
        }
      }
      .overview-btn-order {
        border-top: 1px solid #f2f2f2;
        margin-bottom: 2.4rem;
        margin-top: 2rem;
        padding-top: 1.6rem;
        dl {
          display: flex;
          align-items: center;
          justify-content: space-between;
          dt {
            flex: 1 1;
            font-size: var(--font-size-base);
            font-weight: var(--font-weight-bold);
          }
          dd {
            font-size: var(--font-size-3);
            font-weight: var(--font-weight-bold);
            padding-left: 8px;
          }
        }
      }
      .overview-btn-next {
        button {
          font-size: 16px;
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
        }
      }
    }
  }
}
