
div main div a.whatsapp {
    position: fixed;
    right: 65px;
    bottom: 25px;
    height: 65px;
    width: 65px;
    text-align: center;
    background: #25D366;
    padding: 12.5px;
    border-radius: 50px;
    margin-top: 200px;
    color:#25D366;
    z-index: 99999 !important;
  }
  
  .whatsapp-icon {
    margin-top:13px;
  }