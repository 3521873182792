.order-state {
  display: inline-block;
  line-height: 20px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: var(--border-radius-small);
  padding: 0 0.8rem;
  &--geekblue {
    color: #1d39c4;
    background: #f0f5ff;
    border-color: #adc6ff;
  }
  &--red {
    color: #cf1322;
    background: #fff1f0;
    border-color: #ffa39e;
  }
  &--green {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
  }
  &--purple {
    color: #531dab;
    background: #f9f0ff;
    border-color: #d3adf7;
  }
  &--magenta {
    color: #c41d7f;
    background: #fff0f6;
    border-color: #ffadd2;
  }
}
