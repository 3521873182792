.skeleton {}

.product-property {
    margin-top: 1.6rem;

    &__title {
        margin-bottom: 1.2rem;
        font-size: var(--font-size-6);

        span {
            color: #999;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;

        .property-item {
            box-shadow: inset 0 0 0 1px #ccc;
            border-radius: var(--border-radius-small);
            margin-right: 1.2rem;
            margin-bottom: 1.2rem;
            cursor: pointer;

            &.active {
                box-shadow: inset 0 0 0 2px #ff4747;
            }

            &:hover {
                box-shadow: inset 0 0 0 2px #ff4747;
            }

            .property-text {
                height: 32px;
                line-height: 32px;
                padding: 0 1.2rem;
                white-space: nowrap;
            }
        }
    }
}