.cate-profile {
  padding: 4rem 0;
  .profile-title {
    margin-bottom: 1.6rem;
    h1 {
      color: var(--color-text-alt);
      font-size: var(--font-size-2);
      line-height: 1.2;
    }
  }
  .profile-subtitle {
    padding-top: 2.4rem;
    padding-bottom: 2rem;
    h4 {
      padding-bottom: 1rem;
    }
    p {
      color: var(--color-text-alt);
    }
  }
  .profile-content {
    max-width: 80rem;
    .profile-box {
      padding: 1.6rem;
    }
    .profile-form {
      width: 100%;
      padding-top: 0.8rem;
      .flex-outer {
        list-style-type: none;
        li {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          // align-items: center;
          margin-bottom: 2.4rem;
          &.last-row {
            margin-bottom: 0;
          }
          label {
            width: 160px;
            color: var(--color-text-alt);
            font-size: 1.5rem;
            font-weight: 600;
            letter-spacing: 0.09em;
            padding: 0 0.8rem;
          }
          input {
            color: var(--color-text-alt);
            letter-spacing: 0.08em;
            padding-bottom: 1rem;
            padding-top: 1rem;
            &.Mui-disabled {
              background-color: rgba(0, 0, 0, 0.075);
            }
          }
          .input-row {
            width: 73%;
            .MuiTextField-root {
              width: 100%;
            }
          }
          .MuiInputBase-input {
            padding-bottom: 1rem;
            padding-top: 1rem;
          }
          .MuiInputBase-multiline {
            padding-bottom: 1rem;
            padding-top: 1rem;
          }
          button {
            margin-left: auto;
          }
          @media only screen and (max-width: 1024px) {
            label {
              padding: 0.8rem 0;
            }
            .input-row {
              width: 100%;
            }
          }
        }
      }
    }
    @media (min-width: 768px) {
      .profile-box {
        padding: 2.4rem;
      }
    }
  }
}
