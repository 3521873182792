.cate-login {
  min-height: calc(100vh - 72px);
  &__grid {
    min-height: calc(100vh - 72px);
    .login-cover {
      display: flex;
      align-items: center;
      height: 100%;
      user-select: none;
    }
    .login-card {
      display: flex;
      align-items: center;
      height: 100%;
      &__paper {
        width: 100%;
        height: 700px;
        padding: 2.4rem;
        margin: 2.4rem 0;
      }
      .card-header {
        .header-t1 {
          font-size: var(--font-size-3);
          font-weight: var(--font-weight-semibold);
        }
        .header-t2 {
          font-size: var(--font-size-2);
          font-weight: var(--font-weight-bold);
          color: var(--color-primary);
        }
      }
      .card-content {
        height: 85%;
        display: flex;
        align-items: center;
        &__form {
          width: 100%;
          .form-btn-submit {
            margin-top: 1.6rem;
            margin-bottom: 2.4rem;
          }
          .form-link {
            text-align: center;
            color: var(--color-primary);
            font-weight: var(--font-weight-semibold);
            .link-black {
              color: var(--color-text-base);
            }
          }
          .form-link-register {
            padding-top: 2rem;
            padding-bottom: 1rem;
          }
          .form-link-lost-pass {
            padding-top: 1rem;
            padding-bottom: 2rem;
          }
        }
      }
    }
    @media only screen and (max-width: 960px) {
      .login-cover {
        display: none;
      }
      .login-card {
        &__paper {
          height: 600px;
        }
      }
    }
  }
}
