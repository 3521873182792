.cate-order {
  padding: 4rem 0;
  .page-title {
    margin-bottom: 2.4rem;
    h1 {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-semibold);
    }
  }
  .order-group {
    .order-item {
      margin-bottom: 1.6rem;
      &--box {
        display: block;
        border: 1px #ddd solid;
        .box-inner {
          padding: 1.5rem 2rem;
        }
      }
      &__head {
        background-color: #f2f2f2;
        border-radius: 0.8rem 0.8rem 0 0;
        .head-wrap {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          p {
            font-size: 1.4rem;
            color: #565959;
            &.title {
              font-size: 1.3rem;
              font-weight: 500;
            }
          }
          .head-left {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            .row {
              margin-right: 1.6rem;
              &:last-child {
                margin-right: 0;
              }
              :last-child {
                margin-top: 0.4rem;
              }
              .price {
                color: #e62e04;
                font-weight: 600;
              }
            }
          }
          .head-right {
            .order-id {
              text-align: right;
              margin-bottom: 0.4rem;
            }
            .order-actions {
              p {
                color: #0066c0;
                font-weight: 500;
                font-size: 1.3rem;
                &:hover {
                  text-decoration: underline;
                  cursor: pointer;
                  color: var(--color-primary-darker);
                }
              }
            }
          }
        }
      }
      &__body {
        margin-top: -0.1rem;
        border-radius: 0 0 0.8rem 0.8rem;
        .product-wrap {
          margin-top: 1.6rem;
          &:first-child {
            margin-top: 0;
          }
          .product-info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .product-left {
              width: 120px;
              height: 120px;
              a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background: #000;
                  opacity: 0.04;
                  border-radius: var(--border-radius-small);
                }
              }
              img {
                height: 100%;
                border-radius: var(--border-radius-small);
                object-fit: cover;
                user-select: none;
              }
            }
            .product-right {
              flex: 1 1;
              padding-left: 1.6rem;
              .product-title {
                color: #0066c0;
                font-size: 1.6rem;
                font-weight: 500;
                margin-bottom: 0.5rem;
                &:hover {
                  text-decoration: underline;
                  cursor: pointer;
                  color: var(--color-primary-darker);
                }
              }
              .product-category {
                margin-bottom: 0.5rem;
                font-weight: 500;
                span {
                  font-weight: 400;
                }
              }
              .product-property {
                margin-bottom: 0.4rem;
                font-weight: 500;
                span {
                  font-weight: 400;
                }
              }
              .product-amount {
                font-size: 1.5rem;
                font-weight: 600;
              }
            }
          }
        }
        .product-empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 180px;
            user-select: none;
          }
          p {
            font-size: var(--font-size-base);
            font-weight: 500;
            color: #565959;
          }
        }
      }
      .MuiSkeleton-root {
        height: 221px;
        border-radius: var(--border-radius-medium);
      }
    }
    .order-empty {
      text-align: center;
      color: #565959;
      overflow: hidden;
      svg {
        width: 160px;
        height: 160px;
        margin: 2.4rem 0 1.6rem;
      }
      p {
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-semibold);
      }
    }
  }
}
