.cate-main-topbar {
  background-color: var(--color-background-base);
  height: 72px;
  &__tollbar {
    height: 100%;
  }
  .topbar-left {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    .topbar-logo {
      width: 96px;
      margin-top: 0.5rem;
      // padding: 0.8rem 0 0.4rem 0;
      user-select: none;
    }
    .topbar-link {
      margin-left: 1.6rem;
      button {
        font-weight: var(--font-weight-semibold);
      }
    }
    @media only screen and (max-width: 1024px) {
      .topbar-logo {
        margin-left: 0.5rem;
      }
    }
  }
  .topbar-right {
    display: flex;
    align-items: center;
    height: 100%;
    .topbar-button-user {
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: 0.8rem;
      .MuiSvgIcon-root {
        font-size: 2.4rem;
      }
      .button-user-text {
        display: block;
        flex-direction: column;
        align-items: flex-start;
        max-width: 100px;
        color: var(--color-text-base);
        font-size: var(--font-size-7);
        line-height: 15px;
        /*word-break: break-all;*/
        white-space: normal;
      }
      @media only screen and (max-width: 768px) {
        .MuiButton-root {
          line-height: 1.2;
          padding-left: 1.2rem;
          padding-right: 1.2rem;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    &__tollbar {
      &.MuiToolbar-gutters {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }
    }
  }
}
