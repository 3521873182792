.product-detail-modal {
  .MuiPaper-root {
    max-width: 840px;
    width: 840px;
  }
  .product-modal {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 2.4rem;
    &__appbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.8rem;
      button {
        min-width: 32px;
        padding: 0.6rem 0;
        margin-right: 0.8rem;
      }
      .appbar-title {
        font-size: var(--font-size-5);
        font-weight: var(--font-weight-semibold);
        color: #363435;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__left {
      position: relative;
      margin-right: 2.4rem;
      .modal-left-category {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.8rem;
      }
      .modal-left-image {
        width: 336px;
        height: 336px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          user-select: none;
          border-radius: var(--border-radius-small);
        }
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .modal-body-top {
        .modal-title {
          font-size: var(--font-size-3);
          font-weight: var(--font-weight-semibold);
          margin-bottom: 1.6rem;
        }
        .modal-desc {
          font-size: var(--font-size-normal);
          color: var(--color-text-alt);
        }
      }
      .modal-body-bottom {
        .modal-price {
          text-align: end;
          font-size: var(--font-size-4);
          font-weight: var(--font-weight-semibold);
          margin-bottom: 2.4rem;
        }
        .modal-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          &__counter {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 8px;
            .counter-display {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 36px;
              height: 36px;
              font-weight: var(--font-weight-semibold);
            }
            button {
              width: 36px;
              height: 36px;
              min-width: 36px;
              padding: 0;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .product-modal {
      flex-direction: column;
      padding: 1.6rem 1.6rem 3.2rem 1.6rem;
      &__left {
        margin-right: 0;
        margin-bottom: 1.6rem;
        .modal-left-image {
          width: 100%;
          height: 100%;
          max-height: 252px;
        }
      }
      &__right {
        height: 100%;
      }
    }
  }
}
