.store-stepper {
  .MuiStepper-root {
    padding: 0 0 2.4rem;
  }
  .MuiStepLabel-label.MuiStepLabel-completed,
  .MuiStepLabel-label.MuiStepLabel-active {
    color: #0e0e10;
  }
  .MuiStepLabel-label {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0.8rem;
  }
}
