.cate-change-pwd {
  padding: 4rem 0;
  .change-pwd-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    &__box {
      padding: 2.4rem 4rem;
      .box-style {
        display: flex;
        flex-direction: column;
        text-align: center;
        h1 {
          font-size: var(--font-size-2);
          line-height: 47px;
          letter-spacing: -0.90725px;
          margin-bottom: 0.8rem;
        }
        p {
          font-size: var(--font-size-5);
          line-height: 24px;
          letter-spacing: -0.4px;
          margin-bottom: 2.4rem;
        }
        .change-pwd-form {
          display: flex;
          flex-direction: column;
        }
        .field-margin {
          margin-bottom: 1.6rem;
        }
        .MuiButton-root {
          font-size: var(--font-size-5);
          font-weight: var(--font-weight-semibold);
          padding: 0.8rem 3.2rem;
          margin: 24px auto 0px;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      &__box {
        padding: 2.4rem;
        .box-style {
          h1 {
            font-size: 28px;
            line-height: 33px;
          }
        }
      }
    }
  }
}
