.cate-blog-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: calc(100vh - 72px);
  min-height: 246px;
  background-color: var(--color-background-base);
  &__background {
    position: relative;
    // height: 100%;
    width: 1120px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }
    .background-content {
      position: absolute;
      top: 55%;
      left: 42%;
      transform: translate(-50%, -50%);
      &__title {
        margin-bottom: 1.6rem;
      }
      &__desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
