.cate-courses {
  padding: 4rem 0;
  .course-item {
    &__cover {
      border-bottom: 1px #ddd solid;
      img {
        display: block;
        width: 100%;
        height: 176px;
        object-fit: cover;
        user-select: none;
        border-top-left-radius: var(--border-radius-medium);
        border-top-right-radius: var(--border-radius-medium);
      }
      .MuiSkeleton-root {
        width: 100%;
        height: 176px;
        border-top-left-radius: var(--border-radius-medium);
        border-top-right-radius: var(--border-radius-medium);
      }
    }
    &__content {
      padding: 1.6rem;
      .item-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 0.8rem;
      }
      .item-subtitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
