.cate-product-detail {
  width: 100%;
  height: 100%;
  padding: 3.2rem 0;

  .product-top-wrap {
    padding: 0 1.6rem;
    margin-top: -0.8rem;
    margin-bottom: 1.6rem;

    .MuiButton-text {
      padding: 0.2rem 0.2rem;

      .MuiButton-startIcon {
        margin-right: 0.4rem;

        svg {
          font-size: 1.6rem;
        }
      }
    }
  }

  .product-main-wrap {
    display: flex;
    flex-direction: row;
    padding: 2.4rem;
    margin-bottom: 2.4rem;
    background-color: var(--color-background-base);

    .img-view-wrap {
      width: 450px;

      .image-viewer {
        width: 450px;
        overflow: hidden;

        .image-view-magnifier-wrap {
          width: 450px;
          height: 450px;
          overflow: hidden;
          cursor: pointer;
          text-align: center;
          direction: ltr;

          img {
            width: 100%;
            height: 100%;
            user-select: none;
            object-fit: contain;
            background-color: #fff;
            border-radius: var(--border-radius-small);
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: #fff;
            border-radius: var(--border-radius-small);
          }
        }

        .images-view-wrap {
          width: 100%;
          overflow-x: auto;

          .images-view-list {
            background-color: var(--color-background-base);
            padding: 0;
            margin: 1.6rem 0 2.4rem;
            list-style-type: none;
            text-align: center;

            li {
              display: inline-block;
              vertical-align: middle;
              box-shadow: inset 0 0 0 1px #ccc;
              border-radius: var(--border-radius-small);
              cursor: pointer;
              margin-right: 1rem;

              &.active {
                box-shadow: inset 0 0 0 2px #ef0404;
              }

              .images-view-item {
                width: 54px;
                height: 54px;
                display: flex;
                justify-content: center;
                align-items: center;

                object,
                img,
                video {
                  max-height: 50px;
                  max-width: 50px;
                  object-fit: cover;
                }

                video::-webkit-media-controls {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .product-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin-left: 32px;

      .product-extra {
        .MuiBreadcrumbs-root {
          font-size: var(--font-size-7);
          font-family: var(--font-family);
          font-weight: 500;

          .MuiBreadcrumbs-separator {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
          }
        }

        .MuiButton-text {
          padding: 0.2rem 0.2rem;

          .MuiButton-startIcon {
            margin-right: 0.4rem;

            svg {
              font-size: 1.6rem;
            }
          }
        }
      }

      .product-title {
        margin-top: 1.6rem;
        margin-bottom: 1rem;

        h1 {
          font-size: var(--font-size-3);
          font-weight: var(--font-weight-semibold);
          line-height: 30px;
          width: fit-content;
        }

        &__brand {
          margin-bottom: 0.4rem;
          font-weight: 500;
          color: #5b7086;
        }
      }

      .product-price {
        margin: 2.4rem 0 1rem;

        &__current {
          display: inline-block;
          font-size: var(--font-size-3);
          font-weight: var(--font-weight-bold);
          vertical-align: middle;
        }
      }

      .product-stock {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 1.6rem;
        font-size: var(--font-size-7);

        .MuiChip-root {
          color: #ff4747;
          border: 2px solid #ff4747;
          background-color: #fff1f1;
          font-family: var(--font-family);
          font-size: var(--font-size-6);
          font-weight: var(--font-weight-semibold);
          margin-left: 0.8rem;
        }
      }

      .product-deduction {
        color: var(--color-text-green);
        font-weight: bold;
      }

      .product-property {
        margin-top: 1.6rem;

        &__title {
          margin-bottom: 1.2rem;
          font-size: var(--font-size-6);

          span {
            color: #999;
          }
        }

        &__list {
          display: flex;
          flex-wrap: wrap;

          .property-item {
            box-shadow: inset 0 0 0 1px #ccc;
            border-radius: var(--border-radius-small);
            margin-right: 1.2rem;
            margin-bottom: 1.2rem;
            cursor: pointer;

            &.active {
              box-shadow: inset 0 0 0 2px #ff4747;
            }

            &:hover {
              box-shadow: inset 0 0 0 2px #ff4747;
            }

            .property-text {
              height: 32px;
              line-height: 32px;
              padding: 0 1.2rem;
              white-space: nowrap;
            }
          }
        }
      }

      .product-quantity {
        margin-top: 1.6rem;

        &__title {
          font-size: var(--font-size-7);
          margin-bottom: 0.8rem;
        }

        &__picker {
          display: inline-block;
          width: 104px;

          .input-group {
            display: inline-table;
            border-collapse: separate;
            border-spacing: 0;
            line-height: 0;
            width: 100%;

            .input-addon {
              display: table-cell;
              vertical-align: middle;
              white-space: nowrap;
            }

            .input-remove,
            .input-add {
              button {
                background-color: #f2f2f2;
              }

              svg {
                color: #000;
              }
            }

            .input-text {
              height: 36px;
              padding-left: 0.8rem;
              padding-right: 0.8rem;

              input {
                text-align: center;
                padding: 0.4rem 0 0.4rem;

                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                /* Firefox */
                &[type='number'] {
                  -moz-appearance: textfield;
                }
              }
            }
          }
        }
      }

      .product-action {
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;

        button {
          height: 44px;
          padding-left: 2.4rem;
          padding-right: 2.4rem;
        }
      }

      .divider-line {
        width: 100%;
        height: 0.2rem;
        background-color: #f9f9f9;
      }
    }
  }

  .product-extra-wrap {
    margin-bottom: 2.4rem;
    background-color: var(--color-background-base);

    .product-extra-title {
      padding: 2.4rem 0 1.6rem;
      margin-left: 2.4rem;
      margin-right: 2.4rem;
      border-bottom: 2px solid #f9f9f9;

      h2 {
        font-size: var(--font-size-4);
        font-weight: var(--font-weight-semibold);
        line-height: 19px;
        width: fit-content;
      }
    }

    .product-extra-info {
      padding: 2.4rem;

      .table-row-gray {
        background-color: #f8f8f8;

        th {
          width: 210px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    & {
      padding: 2.4rem 0;

      .MuiContainer-root {
        padding: 0;
      }

      .product-main-wrap {
        flex-direction: column;

        .img-view-wrap {
          width: 100%;

          .image-viewer {
            width: 100%;

            .image-view-magnifier-wrap {
              width: 100%;
              height: 350px;

              img {
                object-fit: cover;
              }
            }
          }
        }

        .product-info {
          margin-left: 0;
        }
      }

      .product-extra-wrap {
        .product-extra-info {
          .table-row-gray {
            th {
              width: 140px;
            }
          }
        }
      }
    }
  }

  //estilos para comment de Ant
  /* tile uploaded pictures */
  .comment-action {
    padding-left: 8px;
    cursor: 'auto';
  }

  [class*='-col-rtl'] .comment-action {
    padding-right: 8px;
    padding-left: 0;
  }

  //fin de estilos para comment de Ant
}