.cate-experiences {
  padding: 4rem 0;
  .experience-card {
    position: relative;
    width: 100%;
    background-color: var(--color-background-base);
    border-radius: var(--border-radius-medium);
    // box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
    overflow: hidden;
    border: 0.1rem #ddd solid;
    &:hover {
      // box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
      transform: scale(1.04, 1.04);
      .experience-card__info-hover {
        opacity: 1;
      }
      .experience-card__img--hover {
        height: 100%;
        opacity: 0.3;
      }
      .experience-card__info {
        position: relative;
        background-color: transparent;
      }
    }
    &__info-hover {
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 0.7;
      z-index: 999;
      // padding: 1.6rem;
      .info-like {
        width: 2.4rem;
      }
      .info-status {
        padding: 0.2rem 0.5rem;
        background-color: #f6171733;
        float: right;
        width: 90px;
        padding: 3px;
        text-align: center;
        margin-left: 3px;
        border-radius: var(--border-radius-small);
      }
      .info-clock {
        float: right;
        padding: 0.2rem 0.5rem;
        background-color: rgba(0, 82, 73, 0.2);
        border-radius: var(--border-radius-small);
      }
      .info-clock-icon {
        width: 1.6rem;
        vertical-align: middle;
        fill: #005249;
      }
      .info-clock-text {
        font-size: 1.4rem;
        font-weight: 600;
        color: #005249;
        vertical-align: middle;
        margin-left: 0.4rem;
      }
    }
    &__img {
      width: 100%;
      height: 235px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      visibility: hidden;
    }
    &__img--hover {
      position: absolute;
      top: 0;
      width: 100%;
      height: 235px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: 0.2s all ease-out;
    }
    &__info {
      height: 109px;
      background-color: var(--color-background-base);
      padding: 1.6rem 2.4rem 2.4rem 2.4rem;
      z-index: 2;
      .info-category {
        text-transform: uppercase;
        font-size: 1.4rem;
        letter-spacing: 2px;
        font-weight: 600;
        color: #7a7a7a;
      }
      .info-title {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }
      .info-price {
        font-size: 1.4rem;
        font-weight: 500;
      }
      .info-author {
        font-weight: 600;
        text-decoration: none;
        color: var(--color-primary);
      }
    }
    .MuiSkeleton-text {
      transform: scale(1, 1);
    }
  }
}
