.cate-home-subscribe {
  padding: 4rem 0 6.4rem 0;

  .MuiGrid-root {
    justify-content: center;
  }

  &__header {
    margin-bottom: 4rem;
  }

  .pricing-card {
    height: 780px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 920px;
    padding: 3.2rem 2.4rem;

    button {
      display: flex;
      margin: auto;
    }

    &--default {
      background-color: var(--color-background-base);
    }

    &--primary {
      background-color: var(--color-background-primary);
    }

    &__body {
      height: 100%;

      .pricing-header {
        height: 10%;
        font-size: 2.7rem;
        font-weight: var(--font-weight-bold);
        text-align: center;
      }

      .pricing-img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 160px;
        }
      }

      .pricing-total {
        margin-bottom: 2.4rem;

        .price {
          font-weight: var(--font-weight-bold);
          font-size: 4.2rem;
          margin-right: 0.8rem;
        }
      }

      .pricing-desc {
        font-size: 1.8rem;
        margin-bottom: 1.6rem;
        margin-top: 2rem;
      }

      .pricing-list {
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 960px) {
          img {
            width: 400px;
          }

        }

        img {
          height: 200px;
        }

        .categories-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 15px 0px;

          .concepts-container {
            margin: 10px 0px;
          }

          .button-container {
            display: flex;
            flex-direction: column;

            .MuiButtonBase-root {
              align-self: stretch;
              margin-bottom: 15px;
            }
          }

          p {
            margin: 4px 0px;
            font-weight: bold;
            font-size: 1.7rem;
          }
        }

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        li {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 2.4rem 0;
        }

        .list-icon {
          width: 2rem;
          height: 2rem;
          user-select: none;
        }

        .list-text {
          font-size: 1.8rem;
          margin-left: 0.8rem;
        }
      }
    }
  }

  .pricing-action-primary {
    background-color: #fff;
    color: var(--color-primary);
  }
}