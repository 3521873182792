.cate-auth-dialog {
  .auth-dialog-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.2rem 5.6rem;
    &__title {
      max-width: 296px;
      margin: 1.6rem 0 2.4rem;
      text-align: center;
    }
    button {
      width: 100%;
      min-height: 48px;
      font-weight: var(--font-weight-semibold);
      &:last-child {
        margin-top: 0.8rem;
      }
    }
    @media only screen and (max-width: 768px) {
      & {
        padding: 2.4rem 3.2rem;
      }
    }
  }
}
