.svg-icon {
  width: 1.3em;
  height: 1.3em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: white;
}

.svg-icon circle {
  stroke: white;
  stroke-width: 3;
}


.cate-exp-detail {
  margin: 4rem 0;

  .exp-detail-cover {
    position: relative;
    height: 280px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-top-left-radius: var(--border-radius-medium);
    border-top-right-radius: var(--border-radius-medium);
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(3px);
      background-color: rgba(31, 31, 31, 0.72);
      z-index: 9;
    }

    .cover-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      user-select: none;
    }

    .cover-text {
      position: absolute;
      bottom: 24px;
      left: 24px;
      right: auto;
      z-index: 99;

      h1 {
        font-size: var(--font-size-2);
        color: white;
      }
    }
  }

  .exp-detail-content {
    padding: 2.4rem;

    .content-category {
      overflow-x: auto;
      justify-content: space-between;


      &__group {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 16px;
        margin-bottom: 2rem;
      }
    }

    .category-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      min-width: 160px;

      &__text {
        margin-left: 1.6rem;

        p {
          color: #999;
        }

        h6 {
          font-weight: 600;
        }
      }

      svg {
        display: block;
        height: 32px;
        width: 32px;
      }
    }

    .nui-content-post {
      :first-child {
        margin-top: 0;
      }
    }

    .conten-price {
      margin: 2.4rem 0;

      span {
        font-weight: 600;
        color: #999;
      }

      p {
        font-size: var(--font-size-3);
        font-weight: var(--font-weight-bold);
        margin-top: 0.5rem;
      }
    }

    .content-action {
      button {
        font-size: 16px;
        padding: 0.8rem 4rem;
      }

      svg {
        font-size: 2.4rem;
      }
    }
  }

  .exp-detail-gallery {
    .exp-detail-video {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 30px 0px;

      video {
        width: 100%;
        max-width: 725px;
        min-width: 335px;
        border-radius: 15px;
      }
    }

    .galllery-item {
      height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }

      .MuiSkeleton-text {
        transform: scale(1, 1);
      }
    }
  }
}