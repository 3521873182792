.cate-shipping {
  padding: 4rem 0;
  .shipping-card {
    padding: 1.6rem 2.4rem;
    @media only screen and (max-width: 768px) {
      padding: 1.6rem;
    }
  }
  .shipping-location {
    .location-title {
      font-weight: var(--font-weight-semibold);
      margin-bottom: 1.6rem;
    }
    .location-tabs {
      margin-bottom: 2.4rem;
      .MuiTab-wrapper {
        flex-direction: row;
        svg {
          margin-right: 0.8rem;
        }
      }
    }
    .location-summary {
      font-size: var(--font-size-6);
    }
    .location-form {
      .input-container {
        margin-top: 1.6rem;
        &:last-child {
          margin-bottom: 1.6rem;
        }
        label,
        .input-label {
          display: block;
          font-weight: var(--font-weight-semibold);
          margin-bottom: 0.8rem;
        }
        .input-row {
          width: 50%;
          .MuiTextField-root {
            width: 100%;
          }
          .MuiInputBase-input {
            padding-bottom: 1rem;
            padding-top: 1rem;
          }
        }
      }
      .btn-action {
        width: 50%;
        margin-top: 2.4rem;
        margin-bottom: 1.6rem;
        button {
          font-size: 16px;
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
        }
      }
      @media only screen and (max-width: 768px) {
        .input-container {
          .input-row {
            width: 100%;
          }
        }
        .btn-action {
          width: 100%;
        }
      }
    }
  }
  .price-overview {
    .overview-title {
      font-weight: var(--font-weight-semibold);
      margin-bottom: 1.6rem;
    }
    .overview-body {
      .price-group {
        margin-bottom: 0.8rem;
        &:last-child {
          margin-bottom: 0;
        }
        dl {
          display: flex;
          align-items: center;
          justify-content: space-between;
          dt {
            flex: 1 1;
            font-size: var(--font-size-base);
            font-weight: 500;
          }
          dd {
            font-size: var(--font-size-base);
            font-weight: 500;
            padding-left: 8px;
          }
        }
      }
    }
    .overview-btn-order {
      border-top: 1px solid #f2f2f2;
      margin-bottom: 2.4rem;
      margin-top: 2rem;
      padding-top: 1.6rem;
      dl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        dt {
          flex: 1 1;
          font-size: var(--font-size-base);
          font-weight: var(--font-weight-bold);
        }
        dd {
          font-size: var(--font-size-3);
          font-weight: var(--font-weight-bold);
          padding-left: 8px;
        }
      }
    }
  }
}
