.products-list-grid {
  .product-card {
    display: inline-block;
    width: 100%;
    &__image {
      position: relative;
      width: 100%;
      height: 224.8px;
      border-top-left-radius: var(--border-radius-medium);
      border-top-right-radius: var(--border-radius-medium);
      border-bottom: 1px #ddd solid;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: var(--border-radius-medium);
        border-top-right-radius: var(--border-radius-medium);
        user-select: none;
      }
      &.cover-disable {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          backdrop-filter: blur(4px);
        }
      }
      .badge-new {
        position: absolute;
        display: flex;
        top: 0.5rem;
        right: 0.5rem;
        padding: 0.4rem 0.8rem;
        color: #fff;
        font-weight: 600;
        background-color: #5de2a3;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius-small);
        user-select: none;
        font-size: 1.6rem;
      }
      .badge-stock {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotateZ(-12deg);
        color: #fff;
        background-color: #cf454e;
        text-align: center;
        font-weight: 600;
        box-shadow: 0px 3px 1px -2px rgba(231, 83, 83, 0.2),
          0px 2px 2px 0px rgba(231, 83, 83, 0.14), 0px 1px 5px 0px rgba(231, 83, 83, 0.12);
        border-radius: var(--border-radius-small);
        padding: 0.8rem 1.4rem;
        z-index: 500;
        user-select: none;
        p {
          font-size: 1.7rem;
        }
      }
    }
    &__body {
      width: 100%;
      min-height: 128px;
      // width: calc(100% - 130px);
      &__wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        min-height: 128px;
        padding: 1rem;
        .card-body-top {
          .body-brand {
            margin-bottom: 0.4rem;
          }
          .body-title {
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .body-price-normal {
            margin-top: 1rem;
            &__price {
              font-size: var(--font-size-);
              font-weight: var(--font-weight-semibold);
            }
            &__unit {
              color: #999;
              font-size: var(--font-size-7);
            }
          }
        }
        .card-body-bottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .body-price {
            font-weight: var(--font-weight-semibold);
          }
          .body-action {
            button {
              line-height: normal;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .product-card {
      &__image {
        height: 281px;
      }
    }
  }
}
