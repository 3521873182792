.cate-reservation-detail {
  padding: 3.2rem 0;
  .reservation-detail-card {
    padding: 1.6rem;
    @media (min-width: 720px) {
      padding: 2.4rem;
    }
    .detail-info {
      h6 {
        font-size: 1.8rem;
        font-weight: 600;
      }
    }
    .content-space {
      margin-bottom: 1.6rem;
    }
    .text-overline {
      font-weight: 700;
      line-height: 1.5;
      color: #999;
      text-transform: uppercase;
      margin-bottom: 0.4rem;
    }
    .text-body {
      font-weight: 500;
      line-height: 1.57143;
    }
    .info-price {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      &:first-child {
        border-top: 1px solid #f2f2f2;
        padding-top: 2rem;
        margin-bottom: 0.8rem;
      }
      dt {
        flex: 1 1;
        font-size: var(--font-size-base);
        font-weight: 500;
      }
      dd {
        font-size: var(--font-size-base);
        font-weight: 500;
        padding-left: 8px;
      }
    }
    .price-final {
      border-top: 1px solid #f2f2f2;
      margin-top: 2rem;
      padding-top: 1.6rem;
      dt {
        flex: 1 1;
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-bold);
      }
      dd {
        font-size: var(--font-size-3);
        font-weight: var(--font-weight-bold);
        padding-left: 8px;
      }
    }
  }
  .experience-detail-card {
    &__cover {
      height: 280px;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      .cover-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        user-select: none;
      }
    }
    &__warp {
      padding: 1.6rem;
      @media (min-width: 720px) {
        padding: 2.4rem;
      }
    }
    &__title {
      font-size: var(--font-size-3);
      font-weight: 600;
      margin-bottom: 1.6rem;
    }
    &__category {
      overflow-x: auto;
      justify-content: space-between;
      .category-group {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 16px;
        margin-bottom: 2rem;
      }
      .category-item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        min-width: 160px;
        &__text {
          margin-left: 1.6rem;
          p {
            color: #999;
          }
          h6 {
            font-weight: 600;
          }
        }
        svg {
          display: block;
          height: 32px;
          width: 32px;
        }
      }
    }
    &__link {
      margin-top: 0.8rem;
    }
    &__post {
      .nui-content-post {
        :first-child {
          margin-top: 2.4rem;
        }
      }
    }
    &__video {
      margin-top: 4rem;
      text-align: center;
      video {
        outline: none;
        border-radius: var(--border-radius-medium);
      }
    }
  }
  .experience-detail-gallery {
    margin-top: 2.4rem;
    .galllery-item {
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
      .MuiSkeleton-text {
        transform: scale(1, 1);
      }
    }
  }
}
