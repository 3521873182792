.cate-products {
  padding: 4rem 0;
  &__wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .cate-products-filter {
      position: sticky;
      top: 90px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 550px;
      width: 100%;
      max-width: 144px;
      min-width: 144px;
      margin-right: 2.4rem;
      z-index: 8;
      &__title {
        text-align: center;
        padding-top: 2.4rem;
      }
    }
    .cate-products-list {
      width: 100%;
      &__wrap {
        padding-bottom: 3.2rem;
        .product-list-title {
          padding: 1.6rem 0;
          .product-result {
            &__label {
              font-weight: var(--font-weight-semibold);
            }
            &__amount {
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    & {
      padding: 2.4rem 0;
    }
    &__wrap {
      flex-direction: column;
      .cate-products-filter {
        top: 80px;
        height: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 2.4rem;
        &__title {
          padding-top: 1.6rem;
        }
      }
      .cate-products-list {
        &__wrap {
          padding-bottom: 3.2rem;
        }
      }
    }
  }
}
