.cate-subscription {
  padding: 4rem 0;

  .subscription-title {
    margin-bottom: 1.6rem;

    h1 {
      color: var(--color-text-alt);
      font-size: var(--font-size-2);
      line-height: 1.2;
    }
  }

  .subscription-subtitle {
    padding-top: 2.4rem;
    padding-bottom: 2rem;

    h4 {
      padding-bottom: 1rem;
    }

    p {
      color: var(--color-text-alt);
    }
  }

  .subscription-content {
    max-width: 80rem;

    .subscription-box {
      padding: 1.6rem;
    }

    .subscription-form {
      width: 100%;
      padding-top: 0.8rem;

      .flex-outer {
        padding: 20px;
        list-style-type: none;

        .total-price {
          margin-bottom: 2px;

          h1 {
            font-size: var(--font-size-4);

            span {
              padding: 5px 8px;
              background-color: var(--color-primary);
            }
          }
        }

        li {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          // align-items: center;
          margin-bottom: 2.4rem;

          &.last-row {
            margin-bottom: 0;
          }

          label {
            width: 160px;
            color: var(--color-text-alt);
            font-size: 1.5rem;
            font-weight: 600;
            letter-spacing: 0.09em;
            padding: 0 0.8rem;
          }

          input {
            color: var(--color-text-alt);
            letter-spacing: 0.08em;
            padding-bottom: 1rem;
            padding-top: 1rem;

            &.Mui-disabled {
              background-color: rgba(0, 0, 0, 0.075);
            }
          }

          .input-row {
            width: 73%;

            .MuiTextField-root {
              width: 100%;
            }
          }

          .MuiInputBase-input {
            padding-bottom: 1rem;
            padding-top: 1rem;
          }

          .MuiInputBase-multiline {
            padding-bottom: 1rem;
            padding-top: 1rem;
          }

          button {
            margin-left: auto;
          }

          @media only screen and (max-width: 1024px) {
            label {
              padding: 0.8rem 0;
            }

            .input-row {
              width: 100%;
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      .subscription-box {
        padding: 2.4rem;
      }
    }
  }
}