.cat-home-carousel {
  background-color: var(--color-background-base);
  padding: 6.4rem 0 11.2rem 0;
  &__header {
    margin-bottom: 3.2rem;
  }
}

.home-carousel-card {
  margin: 0 4px;
  position: relative;
  height: 250px;
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-elevation-1);
  &__cover {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      border-radius: var(--border-radius-medium);
    }
  }
  &__content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 1.6rem 0;
    background-color: var(--color-background-primary);
    border-bottom-right-radius: var(--border-radius-medium);
    border-bottom-left-radius: var(--border-radius-medium);
    opacity: 1;
    .content-title {
      font-size: var(--font-size-4);
      font-weight: 500;
      color: var(--color-text-white);
    }
  }
}
