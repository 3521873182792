.cat-home-about {
  padding: 4rem 0 6.4rem 0;
  &__image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      border-radius: var(--border-radius-large);
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    .body-title {
      font-weight: var(--font-weight-bold);
      margin-bottom: 1.6rem;
    }
    p {
      line-height: 22px;
    }
  }
}
