.cate-blog-list {
  width: 100%;
  .blog-card {
    // height: 338px;
    box-shadow: none;
    border: 1px #ddd solid;
    &__media {
      height: 176px;
      border-bottom: 1px #ddd solid;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 146px;
      h4 {
        font-family: var(--font-family);
        margin-bottom: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        font-family: var(--font-family);
        font-size: var(--font-size-base);
        margin-bottom: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .content-action {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--color-primary);
        .action-text {
          font-size: var(--font-size-base);
          font-weight: var(--font-weight-semibold);
          margin-right: 0.4rem;
        }
        .action-icon {
          height: 24px;
        }
      }
    }
  }
}
