.nui-font-size-1 {
  font-size: 5.4rem !important;
  font-size: var(--font-size-1) !important;
}
.nui-font-size-2 {
  font-size: 3.6rem !important;
  font-size: var(--font-size-2) !important;
}
.nui-font-size-3 {
  font-size: 2.4rem !important;
  font-size: var(--font-size-3) !important;
}
.nui-font-size-4 {
  font-size: 1.8rem !important;
  font-size: var(--font-size-4) !important;
}
.nui-font-size-5 {
  font-size: 1.6rem !important;
  font-size: var(--font-size-5) !important;
}
.nui-font-size-6 {
  font-size: 1.4rem !important;
  font-size: var(--font-size-6) !important;
}
.nui-font-size-7 {
  font-size: 1.2rem !important;
  font-size: var(--font-size-7) !important;
}
.nui-font-size-base {
  font-size: 1.4rem !important;
  font-size: var(--font-size-base) !important;
}

.nui-regular {
  font-weight: 400;
  font-weight: var(--font-weight-normal);
}
.nui-semibold,
.nui-strong {
  font-weight: 600;
  font-weight: var(--font-weight-semibold);
}
.nui-bold {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}

.nui-italic {
  font-style: italic;
}
.nui-underline {
  text-decoration: underline;
}
.nui-strikecase {
  text-decoration: line-through;
}
.nui-upcase {
  text-transform: uppercase;
}
.nui-capcase {
  text-transform: capitalize;
}

.nui-text-base {
  color: var(--color-text-base);
}
.nui-text-alt {
  color: var(--color-text-alt);
}
.nui-text-white {
  color: var(--color-text-white);
}

.nui-background-body {
  background-color: var(--color-background-body) !important;
}
.nui-background-base {
  background-color: var(--color-background-base);
}
.nui-background-alt {
  background-color: var(--color-background-alt);
}

.nui-elevation-1 {
  box-shadow: var(--shadow-elevation-1);
}
.nui-elevation-2 {
  box-shadow: var(--shadow-elevation-2);
}
.nui-elevation-3 {
  box-shadow: var(--shadow-elevation-3);
}
.nui-elevation-4 {
  box-shadow: var(--shadow-elevation-4);
}

.nui-border-radius-none {
  border-radius: var(--border-radius-none);
}
.nui-border-radius-small {
  border-radius: var(--border-radius-small);
}
.nui-border-radius-medium {
  border-radius: var(--border-radius-medium);
}
.nui-border-radius-large {
  border-radius: var(--border-radius-large);
}

.nui-cursor-default {
  cursor: default;
}
.nui-cursor-auto {
  cursor: auto;
}
.nui-cursor-not-allowed {
  cursor: not-allowed;
}
.nui-cursor-pointer {
  cursor: pointer;
}

.nui-block {
  display: block;
}

.nui-no-decoration {
  color: inherit;
  text-decoration: none;
}

.nui-main-title {
  margin-bottom: 1.6rem;
  h1 {
    color: var(--color-text-alt);
    font-size: var(--font-size-2);
    line-height: 1.2;
  }
}

.nui-paper {
  position: relative;
  background-color: var(--color-background-base);
  border-radius: var(--border-radius-medium);
  box-shadow: none;
  overflow: hidden;
  &--shadow {
    box-shadow: var(--shadow-elevation-1);
  }
  &--border {
    display: block;
    border: 0.1rem #ddd solid;
  }
}

.nui-box {
  display: block;
  border: 1px #ddd solid;
  .box-inner {
    padding: 1.5rem 2rem;
  }
}

.nui-skeleton-full {
  &.MuiSkeleton-text {
    transform: scale(1, 1);
  }
}

.nui-empty-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(80vh - 72px);
  color: #565959;
  svg {
    margin-top: -4rem;
    font-size: 16rem;
  }
  h4 {
    margin-top: 2.4rem;
  }
}

.nui-mg-b-1 {
  margin-bottom: 1rem;
}

.nui-mg-b-2 {
  margin-bottom: 2rem;
}

.nui-mg-t-1 {
  margin-top: 1rem;
}

.nui-content-post {
  h2 {
    letter-spacing: -0.016em;
    line-height: 4.2rem;
    font-size: 3.4rem;
    margin-bottom: -0.27em;
  }
  h3 {
    letter-spacing: 0;
    line-height: 2.8rem;
    font-size: 2.2rem;
    margin-top: 0.93em;
    margin-bottom: -0.28em;
  }
  p {
    letter-spacing: -0.003em;
    line-height: 2.8rem;
    font-size: 1.8rem;
    word-break: break-word;
    margin-top: 1.46em;
    margin-bottom: -0.46em;
  }
  a {
    color: var(--color-primary);
    text-decoration: none;
    transition: color 0.3s;
  }
  figure {
    margin-top: 3.2rem;
    img {
      display: block;
      width: 100%;
    }
  }
  ol {
    li {
      list-style-type: decimal;
      letter-spacing: -0.003em;
      line-height: 2.8rem;
      font-size: 1.8rem;
      margin-top: 1em;
      margin-bottom: -0.46em;
      margin-left: 2.4rem;
    }
  }
  ul {
    li {
      list-style-type: disc;
      letter-spacing: -0.003em;
      line-height: 2.8rem;
      font-size: 1.8rem;
      margin-top: 1.56em;
      margin-bottom: -0.46em;
      margin-left: 2.4rem !important;
    }
    &.todo-list {
      li {
        list-style-type: none;
        margin-left: 0 !important;
        input {
          margin-left: 0.5rem;
          margin-right: 0.6rem;
        }
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    h2 {
      font-size: 4.8rem;
      letter-spacing: -0.011em;
      line-height: 6rem;
    }
    h3 {
      letter-spacing: 0;
      line-height: 3.6rem;
      font-size: 3rem;
      margin-top: 1.25em;
    }
    p {
      letter-spacing: -0.003em;
      line-height: 3.2rem;
      font-size: 2rem;
      margin-top: 1.85em;
    }
    figure {
      margin-top: 4rem;
    }
    ol {
      li {
        letter-spacing: -0.003em;
        line-height: 3.2rem;
        font-size: 2rem;
        margin-top: 1.05em;
      }
    }
    ul {
      li {
        letter-spacing: -0.003em;
        line-height: 3.2rem;
        font-size: 2rem;
        margin-top: 1.05em;
      }
    }
  }
}

.nui-content-product {
  h2 {
    letter-spacing: -0.016em;
    line-height: 4.2rem;
    font-size: 3rem;
    margin-bottom: -0.27em;
  }
  h3 {
    letter-spacing: 0;
    line-height: 2.8rem;
    font-size: 2rem;
    margin-top: 0.93em;
    margin-bottom: -0.28em;
  }
  p {
    letter-spacing: -0.003em;
    line-height: 2.8rem;
    font-size: 1.6rem;
    word-break: break-word;
    margin-top: 1.36em;
    margin-bottom: -0.46em;
  }
  a {
    color: var(--color-primary);
    text-decoration: none;
    transition: color 0.3s;
  }
  figure {
    margin-top: 3.2rem;
    img {
      display: block;
      width: 100%;
    }
  }
  ol {
    li {
      list-style-type: decimal;
      letter-spacing: -0.003em;
      line-height: 2.8rem;
      font-size: 1.6rem;
      margin-top: 1em;
      margin-bottom: -0.46em;
      margin-left: 2.4rem;
    }
  }
  ul {
    li {
      list-style-type: disc;
      letter-spacing: -0.003em;
      line-height: 2.8rem;
      font-size: 1.6rem;
      margin-top: 1.56em;
      margin-bottom: -0.46em;
      margin-left: 2.4rem !important;
    }
    &.todo-list {
      li {
        list-style-type: none;
        margin-left: 0 !important;
        input {
          margin-left: 0.5rem;
          margin-right: 0.6rem;
        }
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    h2 {
      font-size: 3.2rem;
      letter-spacing: -0.011em;
      line-height: 6rem;
    }
    h3 {
      letter-spacing: 0;
      line-height: 3.6rem;
      font-size: 2.4rem;
      margin-top: 1.25em;
    }
    p {
      letter-spacing: -0.003em;
      line-height: 3.2rem;
      font-size: 1.6rem;
      margin-top: 1.5em;
    }
    figure {
      margin-top: 4rem;
    }
    ol {
      li {
        letter-spacing: -0.003em;
        line-height: 3.2rem;
        font-size: 1.6rem;
        margin-top: 1em;
      }
    }
    ul {
      li {
        letter-spacing: -0.003em;
        line-height: 3.2rem;
        font-size: 1.6rem;
        margin-top: 1em;
      }
    }
  }
}

.text-right {
  text-align: right;
}
.ml-1 {
  margin-left: 1em !important;
}
.ml-2 {
  margin-left: 2em !important;
}
.ml-2px {
  margin-left: 2px !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}

.MuiSnackbarContent-message{
  white-space: pre-wrap
}