.cate-order-detail {
  padding: 3.2rem 0 4rem;
  .order-detail-top {
    margin-bottom: 1rem;
    .MuiButton-text {
      padding: 0.2rem 0.2rem;
      .MuiButton-startIcon {
        margin-right: 0.4rem;
        svg {
          font-size: 1.6rem;
        }
      }
    }
  }
  .page-title {
    margin-bottom: 1.6rem;
    h1 {
      font-size: var(--font-size-3);
      font-weight: var(--font-weight-semibold);
    }
  }
  .order-extra {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
    &__separator {
      display: inline-block;
      width: 1px;
      height: 12px;
      margin: 0 0.8rem;
      background-color: #ddd;
    }
  }
  .order-info {
    border-radius: 0.8rem 0.8rem;
    margin-bottom: 1.6rem;
    .info-subtitle {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    &__address {
      margin-right: 1rem;
      margin-bottom: 1.6rem;
      li {
        line-height: 21px;
      }
    }
    &__method {
      margin-bottom: 1.6rem;
      .method-wrap {
        display: flex;
        align-items: center;
        svg {
          font-size: 3.5rem;
          margin-right: 0.5rem;
        }
      }
    }
    &__resume {
      .resume-space {
        margin-bottom: 0.4rem;
      }
      .resume-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        line-height: 21px;
        font-weight: 400;
        .item-name-last {
          font-weight: 600;
        }
        .item-subtotals-last {
          font-weight: 600;
        }
      }
    }
  }
  .order-products {
    border-radius: 0.8rem 0.8rem;
    ul {
      margin: 0.4rem 0;
    }
    .product-wrap {
      margin-top: 1.6rem;
      &:first-child {
        margin-top: 0;
      }
      .product-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .product-left {
          width: 120px;
          height: 120px;
          a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: #000;
              opacity: 0.04;
              border-radius: var(--border-radius-small);
            }
          }
          img {
            height: 100%;
            border-radius: var(--border-radius-small);
            object-fit: cover;
            user-select: none;
          }
        }
        .product-right {
          flex: 1 1;
          padding-top: 0.4rem;
          padding-left: 1.6rem;
          .product-title {
            color: #0066c0;
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            &:hover {
              text-decoration: underline;
              cursor: pointer;
              color: var(--color-primary-darker);
            }
          }
          .product-category {
            margin-bottom: 0.5rem;
            font-weight: 500;
            span {
              font-weight: 400;
            }
          }
          .product-property {
            margin-bottom: 0.4rem;
            font-weight: 500;
            span {
              font-weight: 400;
            }
          }
          .product-amount {
            font-size: 1.5rem;
            font-weight: 600;
          }
        }
      }
    }
    .product-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.6rem;
      img {
        width: 180px;
        user-select: none;
      }
      p {
        font-size: var(--font-size-5);
        font-weight: 500;
        color: #565959;
      }
    }
  }
}
