.topbar-popover {
  .MuiPopover-paper {
    top: 78px !important;
    ul {
      padding: 1rem;
      .MuiListItem-root {
        color: var(--color-text-base);
        border-radius: var(--border-radius-small);
        padding-left: 1rem;
        padding-right: 1rem;
      }
      .MuiListItemIcon-root {
        min-width: 2.4rem;
        margin-right: 0.8rem;
        color: inherit;
      }
      .topbar-popover-text {
        color: inherit;
        font-weight: var(--font-weight-semibold);
      }
    }
  }
}
