html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  //   'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;
  text-size-adjust: 100%;
}
body {
  background-color: var(--color-background-body);
  color: var(--color-text-base);
  font-size: var(--font-size-base);
}
img {
  max-width: 100%;
  // vertical-align: top;
}
h1,
h2,
strong {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}
h3,
h4 {
  font-weight: 600;
  font-weight: var(--font-weight-semibold);
}
em {
  font-style: italic;
}
.nui-typeset h1,
h1 {
  font-size: 5.4rem;
  font-size: var(--font-size-1);
}
.nui-typeset h2,
h2 {
  font-size: 3.6rem;
  font-size: var(--font-size-2);
}
.nui-typeset h3,
h3 {
  font-size: 2.4rem;
  font-size: var(--font-size-3);
}
.nui-typeset h4,
h4 {
  font-size: 1.8rem;
  font-size: var(--font-size-4);
}
h5 {
  font-size: 1.6rem;
  font-size: var(--font-size-5);
}
.nui-button,
h6,
p {
  font-size: 1.3rem;
  font-size: var(--font-size-base);
}
