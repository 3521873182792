.confirm-email {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        background-color: var(--color-primary);
        border: none;
    }

    button:hover {
        background-color: var(--color-primary-darker);
    }
}