.cate-user-dialog {
  &__title {
    flex: 0 0 auto;
    max-width: 240px;
    padding: 1.8rem 2.6rem 0 2.6rem;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-5);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &__list {
    padding: 1rem !important;
    &.list-1 {
      .user-dialog-item {
        margin-top: 0.5rem;
      }
    }
    .user-dialog-item {
      border-radius: var(--border-radius-small);
    }
    .user-dialog-icon {
      display: flex;
      margin-right: 0.8rem;
    }
    .user-dialog-text {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-base);
    }
  }
}
