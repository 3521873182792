.cate-memberships {
    padding: 4rem 0;

    button {
        background-color: var(--color-primary);
        border: none;
        color: white;
    }

    button:hover {
        background-color: var(--color-primary-darker);
    }
}