.cate-home-banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 400px;
  height: calc(100vh - 72px);
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    // backdrop-filter: blur(3px);
    background-color: #f5f4f0;
    opacity: 0.85;
    z-index: 9;
  }
  &__ilustration {
    position: absolute;
    z-index: 99;
    &.ilustration-1 {
      top: 25%;
      left: 20%;
      transform: translate(-50%, -35%);
      .img-action {
        transition: 0.5s;
        &:hover {
          transform: translate3d(3em, 1em, 5em) scale(1.02);
        }
      }
      .img-root {
        transform: rotate(-10deg);
      }
    }
    &.ilustration-2 {
      top: 35%;
      left: 50%;
      transform: translate(-50%, -65%);
      .img-action {
        transition: 0.5s;
        &:hover {
          transform: translate3d(-2em, 1em, 5em) scale(1.02) translateX(1em) translateY(2em);
        }
      }
      .img-root {
        height: 50vh;
        transform: rotate(0deg);
      }
    }
    &.ilustration-3 {
      top: 25%;
      right: 20%;
      transform: translate(55%, -35%);
      .img-action {
        transition: 0.5s;
        &:hover {
          transform: translate3d(-2em, 1em, 5em) scale(1.02) translateX(-1em);
        }
      }
      .img-root {
        height: 50vh;
        transform: rotate(10deg);
      }
    }
    &--animation-bounce-in {
      animation-delay: 0.2s;
      animation: bounceIn 2s linear forwards;
      opacity: 0;
    }
    &--animation-move-up-down {
      animation: moveUpDown 2.25s linear infinite;
    }
    .img-root {
      height: 65vh;
      overflow: hidden;
      user-select: none;
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 6.4rem;
    opacity: 0;
    animation: fade 2s linear forwards;
    z-index: 99;
    .header-title {
      text-align: center;
      margin-bottom: 3.2rem;
      h1 {
        font-weight: var(--font-weight-semibold);
      }
    }
    .header-action {
      text-align: center;
      button {
        font-size: var(--font-size-5);
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
    }
    @media only screen and (max-width: 1024px) {
      .header-title {
        h1 {
          font-size: var(--font-size-2);
          font-weight: var(--font-weight-bold);
        }
      }
    }
  }
  @keyframes fade {
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.3) translate3d(0, 0, 0);
    }
    50% {
      opacity: 0.9;
      transform: scale(1.1);
    }
    80% {
      opacity: 1;
      // transform: scale(0.89);
      transform: scale(0.95);
    }
    100% {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0);
    }
  }
  @keyframes moveUpDown {
    0% {
      transform: none;
    }
    50% {
      transform: translateX(0) translateY(0.95em);
    }
    100% {
      transform: none;
    }
  }
  @keyframes pulse {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
