.cate-course-detail {
  padding: 4rem 0;
  .course-detail-content {
    max-width: 960px;
    &__card {
      padding: 3.2rem;
    }
    &__title {
      h1 {
        font-size: 4.8rem;
        letter-spacing: -0.011em;
        line-height: 6rem;
        margin-bottom: -0.27em;
      }
    }
    &__categories {
      margin-top: 4rem;
      .MuiChip-root {
        border-radius: 0.8rem;
      }
    }
    &__extra-data {
      padding-top: 2.4rem;
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
      border-top: 0.1rem solid rgba(230, 230, 230, 1);
      .course-author-list {
        display: block;
        .course-author-item {
          position: relative;
          margin-bottom: 3.2rem;
          .author-item-wrap {
            display: block;
            .author-item-image {
              position: absolute;
              width: 80px;
              height: 80px;
              svg {
                width: 80px;
                height: 80px;
              }
            }
            .author-item-info {
              display: block;
              padding-top: 0.8rem;
              padding-left: 9.6rem;
              &__subtitle {
                p {
                  line-height: 1.8rem;
                  font-size: 1.3rem;
                  color: rgba(117, 117, 117, 1);
                }
              }
              &__name {
                h3 {
                  line-height: 2.8rem;
                  font-size: 2.2rem;
                  color: rgba(41, 41, 41, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}
